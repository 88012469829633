import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loading } from "@appkit4/react-components";
import PageHeader from "../../../components/PageHeader";
import Table from "../../../components/Table";
import {  TemplateRow } from "../../../types/Template";
import { Api } from "../../../api/apiHelper";
import {
  handleTemplateById,
  templates as templatesApi,
} from "../../../api/endpoints/templates";
import { errorToast, successToast } from "../../../components/Toast";
import { useConfirmationModalContext } from "../../../contexts/ConfirmationModalContext";
import { createTemplateColumns } from "./helpers/createTemplateColumns";
import { ROUTES } from "../../../constants/routes";

export const Templates = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<TemplateRow[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  const { showConfirmation } = useConfirmationModalContext();

  useEffect(() => {
    setIsLoadingTemplates(true);
    Api.get(templatesApi)
      .then((res) => setTemplates(res))
      .catch(() => errorToast({ message: "Failed to load templates" }))
      .finally(() => {
        setIsLoadingTemplates(false);
      });
  }, []);

  const addTemplate = () => {
    navigate(ROUTES.CREATE_TEMPLATE.path);
  };

  const editTemplate = (row: TemplateRow) => {
    navigate(`${row.id}`);
  };

  const deleteTemplate = (row: TemplateRow) => {
    showConfirmation(
      "Delete Template",
      `Are you sure you want to delete ${row.name} template?`
    ).then((isConfirmed) => {
      if (!isConfirmed) return;
      Api.delete(handleTemplateById(row.id), {})
        .then(() => {
          setTemplates((prev) => prev.filter((i) => i.id !== row.id));
          successToast({ message: "Template has been deleted" });
        })
        .catch((error) =>
          errorToast({ message: `Failed to delete the template. ${error}` })
        );
    });
  };

  const columns = useMemo(
    () => createTemplateColumns(editTemplate, deleteTemplate),
    [templates, editTemplate, deleteTemplate]
  );
  return (
    <>
      <PageHeader
        title="Templates & SubTemplates"
        actions={
          <Button kind="secondary" onClick={addTemplate}>
            Add Template
          </Button>
        }
      />
      {isLoadingTemplates ? (
        <Loading loadingType="circular" indeterminate />
      ) : (
        <Table data={templates} columns={columns} striped condensed />
      )}
    </>
  );
};
