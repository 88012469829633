import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/Authentication/AuthConfig";

import "bootstrap/dist/css/bootstrap.min.css";

const msalInstance = new PublicClientApplication(msalConfig);

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") ?? undefined;
const rootElement = document.getElementById("root");

if (!rootElement) throw Error("App root HTML element not found!");

const root = createRoot(rootElement);

root.render(
  // <MsalProvider instance={msalInstance}>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  // </MsalProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
