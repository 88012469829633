import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { SdkPubSub } from "@pwc-sdk/spa-utils";
import AuthenticationWrapper from "./components/Authentication/AuthenticationWrapper";
import IUserProps from "./types/IUserProps";
import Content from "./routes/Content";
import { LicenseManager } from "ag-grid-enterprise";
import { PublicClientApplication } from "@azure/msal-browser";

const sdkPubSub = new SdkPubSub();
sdkPubSub.register("C4T Props change", "c4t-track-props");

const App = () => {
  const [user, setUser] = useState<IUserProps>();
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
  //@ts-ignore
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_CLIENT_KEY);

  return (
    <Routes>
      <Route
        key="app-layout"
        path="*"
        element={
          <AuthenticationWrapper
            loadingComponent={<div>Authentication...</div>}
            setUser={setUser}
            setMsalInstance={setMsalInstance}
          >
            <Content user={user} msalInstance={msalInstance} />
          </AuthenticationWrapper>
        }
      />
    </Routes>
  );
};

export default App;
