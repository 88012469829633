import { Button } from "@appkit4/react-components/button";
import { Checkbox } from "@appkit4/react-components/checkbox";
import _ from "lodash";
import { createColumnHelper } from "@tanstack/react-table";
import { TemplateRow } from "../../../../types/Template";
import styles from "../Template.module.scss";

const columnHelper = createColumnHelper<TemplateRow>();

export const createTemplateColumns = (
  onEdit: (row: TemplateRow) => void,
  onDelete: (row: TemplateRow) => void
) => [
  columnHelper.accessor((row) => row.name, {
    id: "name",
    header: () => "Name",
    meta: {
      hasTooltip: true,
    },
  }),
  columnHelper.accessor("subTemplates", {
    id: "subTemplates",
    header: () => "SubTemplates",
    cell: (data) =>
      data
        .getValue()
        .map((i) => i.name)
        .join(", "),
    // meta: {
    //   hasTooltip: true,
    // },
  }),
  columnHelper.accessor("isActive", {
    id: "isActive",
    header: () => "Active",
    size: 100,
    cell: (data) => <Checkbox className={styles.cursor} checked={data.cell.getValue()} />,
  }),
  columnHelper.accessor("id", {
    header: () => <span>Actions</span>,
    size: 100,
    cell: (row) => (
      <div className="ap-flex">
        <Button
          add
          kind="text"
          icon="icon-edit-outline"
          className="ap-text-neutral-10"
          onClick={() => onEdit(row.row.original)}
        />
        <Button
          add
          kind="text"
          icon="icon-delete-outline"
          className="ap-text-neutral-10"
          onClick={() => onDelete(row.row.original)}
        />
      </div>
    ),
  }),
];
