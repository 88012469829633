import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { v4 as uuid } from "uuid";
import { clsx } from "clsx";
import * as _ from "lodash";
import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  Input,
  Select,
} from "@appkit4/react-components";
import { NewTemplate, Template } from "../../../../../types/Template";
import { AccordionItemHeader } from "../AccordionItemHeader";
import { SubTemplate } from "../../../../../types/SubTemplate";
import { createNewSubTemplate } from "../../helpers/createNewSubtemplate";
import { formatPayload } from "../../helpers/formatPayload";
import { templateValidationSchema } from "../../helpers/templateValidationSchema";
import { SubTemplateForm } from "../SubTemplate";
import { Api } from "../../../../../api/apiHelper";
import {
  handleTemplateById,
  templates,
  templates as templatesApi,
} from "../../../../../api/endpoints/templates";
import { PageFooter } from "../../../../../components/PageFooter";
import PageHeader from "../../../../../components/PageHeader";
import { useTemplateContext } from "../../../../../contexts/TemplateContext";
import { createNewTemplate } from "../../helpers/createNewTemplate";
import {
  errorToast,
  successToast,
  warningToast,
} from "../../../../../components/Toast";
import { Breadcrumb } from "../../../../../components/Breadcrumb";
import { ROUTES } from "../../../../../constants/routes";
import LoadingPlaceholder from "../../../../../components/LoadingPlaceholder";
import styles from "./TemplateModal.module.scss";

type SubTemplateFormRef = {
  onSubmit: () => Promise<any>;
  errors: Record<string, string>;
};

export default function TemplateForm() {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const ref = useRef<SubTemplateFormRef>(null);
  const { docBotTemplates, editorNotes, subCategories, guidances, exhibits } =
    useTemplateContext();

  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [activeSubTemplateId, setActiveSubTemplateId] = useState<string>("");

  const form = useFormik<Template | NewTemplate>({
    initialValues: {
      name: "",
      subTemplates: [],
      subCategoryIds: [],
      isActive: false,
    },
    validationSchema: templateValidationSchema,
    onSubmit: (values) => confirmTemplateFormChanges(values),
  });

  const pageTitle = templateId ? "Edit Template" : "Add New Template";

  useEffect(() => {
    if (templateId) {
      setIsLoadingTemplate(true);
      Api.get(handleTemplateById(templateId))
        .then((res) => {
          form.setValues({
            ...res,
            subTemplates: res.subTemplates.map((item: SubTemplate) => ({
              ...item,
              guidances: item.guidances?.map((guidance) => ({
                ...guidance,
                id: uuid(),
              })),
              documentConfiguration: JSON.stringify(item.documentConfiguration),
            })),
          });
        })
        .catch(() => errorToast({ message: "Failed to fetch the template" }))
        .finally(() => setIsLoadingTemplate(false));
    } else {
      form.setValues(createNewTemplate());
    }
  }, [templateId]);

  const onClickAccordion = useCallback(
    (id: string) => {
      if (!activeSubTemplateId) {
        setActiveSubTemplateId(id);
      } else {
        ref?.current?.onSubmit().then(() => {
          if (_.isEmpty(ref?.current?.errors)) {
            setActiveSubTemplateId("");
          }
        });
      }
    },
    [activeSubTemplateId, ref?.current]
  );

  const deleteSubTemplate = (id: string) => {
    if (id === activeSubTemplateId) setActiveSubTemplateId("");
    form.setFieldValue(
      "subTemplates",
      form.values.subTemplates.filter((item) => item.id !== id)
    );
  };

  const updateSubTemplate = (value: SubTemplate) => {
    form.setFieldValue(
      "subTemplates",
      form.values.subTemplates.map((item) =>
        item.id === value.id ? value : item
      )
    );
    setActiveSubTemplateId("");
  };

  const addNewSubTemplate = () => {
    const newSubTemplate = createNewSubTemplate(form.values.isActive);
    form
      .setFieldValue("subTemplates", [
        newSubTemplate,
        ...form.values.subTemplates,
      ])
      .then(() => setActiveSubTemplateId(newSubTemplate.id));
  };

  const confirmTemplateFormChanges = async (data: Template | NewTemplate) => {
    const formattedData = formatPayload(data, templateId);
    if (templateId) {
      return Api.put(templates, formattedData)
        .then(() => {
          successToast({ message: "Template has been updated" });
        })
        .catch(() => errorToast({ message: "Failed to update the template" }));
    } else {
      return Api.post(templatesApi, formattedData)
        .then((res) => res.json())
        .then((template) => {
          successToast({ message: "Template has been created" });
          navigate(`${ROUTES.TEMPLATES.path}/${template.id}`);
        })
        .catch(() => errorToast({ message: "Failed to create the template" }));
    }
  };

  const onSave = () => {
    if (activeSubTemplateId) {
      warningToast({
        message: "Please save the SubTemplate before saving the template",
      });
    } else {
      form.handleSubmit();
    }
  };

  return (
    <>
      <Breadcrumb
        list={[ROUTES.TEMPLATES, { name: pageTitle }]}
        className={styles.breadcrumb}
      />
      <div className={clsx(styles.root, "scroll-bar")}>
        <PageHeader title={pageTitle} />
        <div className={styles.content}>
          {isLoadingTemplate && <LoadingPlaceholder overlay="parent" />}
          <Input
            name="name"
            title="Template Name"
            onChange={(_v: string, event: ChangeEvent<HTMLInputElement>) =>
              form.handleChange(event)
            }
            value={form.values.name}
            error={form.touched.name && !!form.errors.name}
            className="ap-mb-spacing-5"
            errorNode={form.errors.name}
          />

          <Select
            multiple
            placeholder="SubCategories"
            dropdownRenderMode="portal"
            className="ap-mb-spacing-4"
            data={subCategories}
            onSelect={(value) => form.setFieldValue("subCategoryIds", value)}
            value={form.values.subCategoryIds}
            error={form.touched.subCategoryIds && !!form.errors.subCategoryIds}
            valueKey="id"
            labelKey="name"
          />

          <Checkbox
            onChange={(checked: boolean) =>
              form.setFieldValue("isActive", checked)
            }
            checked={form.values.isActive}
            className="ap-mb-spacing-4"
          >
            Is Active
          </Checkbox>

          <div className="ap-flex ap-justify-content-space-between ap-align-items-center  ap-mb-spacing-3">
            <div className="ap-typography-heading-s">SubTemplates</div>
            <Button
              kind="secondary"
              compact
              onClick={addNewSubTemplate}
              disabled={!!activeSubTemplateId}
            >
              Add New
            </Button>
          </div>

          <Accordion multiple={false} activeKeys={[activeSubTemplateId]}>
            {form.values.subTemplates.map((item) => (
              <AccordionItem
                key={item.id}
                className={styles.accordionItem}
                templateHeader={() => (
                  <AccordionItemHeader
                    title={item.name}
                    icon={activeSubTemplateId === item.id ? "save" : "edit"}
                    onClick={() => onClickAccordion(item.id)}
                    onDelete={() => deleteSubTemplate(item.id)}
                    isDisabled={Boolean(
                      activeSubTemplateId && activeSubTemplateId !== item.id
                    )}
                  />
                )}
                itemKey={item.id}
                showExpandIcon={false}
                toggleFromHeaderIconOnly
              >
                {activeSubTemplateId === item.id ? (
                  <SubTemplateForm
                    ref={ref}
                    onSave={updateSubTemplate}
                    subTemplate={
                      form.values.subTemplates.find(
                        (i) => i.id === activeSubTemplateId
                      ) as SubTemplate
                    }
                    docDotTemplates={docBotTemplates}
                    editorNotes={editorNotes}
                    allSubTemplateNames={form.values.subTemplates
                      .filter((i) => i.id !== activeSubTemplateId)
                      .map((i) => i.name)}
                    guidances={guidances}
                    exhibits={exhibits}
                    isTemplateDisabled={!form.values.isActive}
                    templateName={form.values.name}
                  />
                ) : (
                  <></>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <PageFooter
          onClickBack={() => navigate(ROUTES.TEMPLATES.path)}
          onSave={onSave}
          isSaving={form.isSubmitting}
        />
      </div>
    </>
  );
}
