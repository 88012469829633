import { ColumnState } from "ag-grid-community";
import { Entity } from "../../types/Entity";
import { IDocument, DocumentStatus } from "../../types/Document";

export const DOCUMENT_TILE_ALL = "All";
export const DOCUMENT_TILE_SIGNATURES = "AwaitingSignature";

export type TileName =
  | DocumentStatus
  | typeof DOCUMENT_TILE_ALL
  | typeof DOCUMENT_TILE_SIGNATURES;

export type CountsByStatusGroup = Record<TileName, number>;

export type DocumentsRes = {
  total: number;
  items: IDocument[];
  limit: number;
  offset: number;
};

export type DocumentsReqParams = {
  clientId?: string;
  limit?: number;
  offset?: number;
  searchTerm?: string;
  tile?: string;
  engagements?: string[];
  entities?: string[];
  subTemplates?: string[];
  statuses?: string[];
  forms?: string[];
  ofroReviewers?: string[];
  sort?: {
    propertyName: string;
    direction: 0 | 1;
  };
};

export type Tile = {
  id: string;
  name: string;
  color: string;
  count: number;
};

export type DocumentsFilter = {
  engagements: Entity[];
  entities: Entity[];
  statuses: Entity[];
  subTemplates: Entity[];
  forms: Entity[];
  ofroReviewers: Entity[];
};

export type FilterIds = {
  engagements: string[];
  entities: string[];
  forms: string[];
  subTemplates: string[];
  statuses: string[];
  ofroReviewers: string[];
};

export type GridState = {
  filterModel: any;
  sortModel?: ColumnState[];
};

export type FilterMetaData = {
  engagements?: Entity[];
  subTemplates?: Entity[];
  clients?: Entity[];
};

export type FilterMetaDataRes = FilterMetaData & {
  statusCounts: Record<string, number>;
};
