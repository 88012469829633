import { useCallback, useEffect, useMemo, memo, useState } from "react";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import {
  CellPosition,
  ColDef,
  FillEndEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  NavigateToNextCellParams,
  RowDragEndEvent,
  RowHeightParams,
  RowModelType,
  SortChangedEvent,
} from "ag-grid-community";
import styles from "./Grid.module.scss";
import { getDefaultColDef, getSidebar } from "./helpers/configurations";

type Props = {
  columns: ColDef[];
  treeData?: boolean;
  rows?: Array<any>;
  isTableExpanded?: boolean;
  enableRangeSelection?: boolean;
  enableFillHandle?: boolean;
  withSidebar?: boolean;
  noDataIndication?: () => JSX.Element;
  getRowHeight?: (params: RowHeightParams) => number;
  onFirstDataRendered?(params: FirstDataRenderedEvent): void;
  onRowsSelected?: (ids: string[]) => void;
  onGridReady?: (params: GridReadyEvent) => void;
  onFilterChanged?: (params: FilterChangedEvent) => void;
  onSortChanged?: (params: SortChangedEvent) => void;
  onNavigateToNextCell?: (
    params: NavigateToNextCellParams
  ) => null | CellPosition;
  onCellEditingStopped?: (params: any) => void;
  onCellEdit?: (rowIdx: number, columnId: string, value: string) => void;
  onRowReorder?: (params: RowDragEndEvent) => void;
  onFillEnd?: (params: FillEndEvent) => void;
  serverSideDataSource?: IServerSideDatasource;
  rowModelType?: RowModelType;
};

function Grid({
  columns,
  rows,
  treeData,
  isTableExpanded = false,
  withSidebar,
  enableRangeSelection,
  enableFillHandle,
  noDataIndication,
  getRowHeight = () => 48,
  onRowsSelected,
  onNavigateToNextCell,
  onGridReady,
  onFilterChanged,
  onSortChanged,
  onCellEdit,
  onRowReorder,
  onFillEnd,
  onFirstDataRendered,
  serverSideDataSource,
  rowModelType,
}: Props) {
  const colDef = useMemo(() => columns, [columns]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  const sidebar = useMemo(
    () => (withSidebar ? getSidebar() : undefined),
    [withSidebar]
  );

  const getRowId = useCallback((params) => params.data.id, []);
  const onSelectionChanged = useCallback(
    (params) => {
      if (onRowsSelected)
        onRowsSelected(params.api.getSelectedRows().map((row) => row.id));
    },
    [onRowsSelected]
  );

  const onRowSelected = useCallback(
    (params) => {
      if (!gridApi || params.source !== "checkboxSelected") return;
      const isParentSelected =
        params.node.isSelected() && params.node.allChildrenCount > 0;
      const isGroupParentDeselected =
        !params.data &&
        !params.node.isSelected() &&
        params.node.allChildrenCount > 0;
      const isGroupChildDeselected =
        !params.node.group &&
        !params.node.isSelected() &&
        !params.node.allChildrenCount;
      const childrenNodes = (params.node?.allLeafChildren || []) as IRowNode[];
      const allChildSelected = childrenNodes.every((c) => c.isSelected());
      if (isParentSelected && !!childrenNodes.length) {
        gridApi.setNodesSelected({ nodes: childrenNodes, newValue: true });
      }

      if (isGroupParentDeselected && allChildSelected) {
        gridApi.setNodesSelected({ nodes: childrenNodes, newValue: false });
      }

      if (isGroupChildDeselected) {
        gridApi.setNodesSelected({
          nodes: [params.node.parent],
          newValue: false,
        });
      }
    },
    [gridApi]
  );

  useEffect(() => {
    gridApi?.setGridOption("columnDefs", columns);
  }, [gridApi]);

  useEffect(() => {
    gridApi?.redrawRows();
  }, [rows]);

  return (
    <div
      className={clsx(isTableExpanded ? styles["root-expanded"] : styles.root)}
    >
      <div
        className={clsx(
          "ag-theme-alpine full-height px-1 pb-1 bg-white rounded-bottom",
          styles.apGrid
        )}
      >
        <AgGridReact
          animateRows
          getRowHeight={getRowHeight}
          cacheQuickFilter={true}
          suppressRowClickSelection
          suppressRowDeselection
          rowSelection={"multiple"}
          scrollbarWidth={18}
          treeData={treeData}
          defaultColDef={defaultColDef}
          columnDefs={colDef}
          rowModelType={rowModelType}
          serverSideDatasource={serverSideDataSource}
          onSelectionChanged={onSelectionChanged}
          onRowSelected={onRowSelected}
          getRowId={getRowId}
          noRowsOverlayComponent={noDataIndication}
          suppressContextMenu
          sideBar={sidebar}
          onGridReady={(params) => {
            setGridApi(params.api);
            if (onGridReady) onGridReady(params);
          }}
          onFilterChanged={onFilterChanged}
          navigateToNextCell={onNavigateToNextCell}
          suppressRowGroupHidesColumns
          onCellEditingStopped={(params) =>
            //@ts-ignore
            onCellEdit(params.node.rowIndex, params.column.colId, params.value)
          }
          stopEditingWhenCellsLoseFocus
          rowDragManaged
          onRowDragEnd={onRowReorder}
          enableRangeSelection={enableRangeSelection}
          enableFillHandle={enableFillHandle}
          fillHandleDirection="y"
          onFillEnd={onFillEnd}
          onSortChanged={onSortChanged}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </div>
  );
}

export default memo(Grid);
