import { NewTemplate, Template } from "../../../../types/Template";

export const formatPayload = (
  values: Template | NewTemplate,
  id: string | undefined
) => ({
  name: values.name,
  id: id,
  subCategoryIds: values.subCategoryIds,
  isActive: values.isActive,
  subTemplates: values.subTemplates.map((item) => ({
    ...item,
    id: item.isNew ? undefined : item.id,
    documentConfiguration: item.documentConfiguration
      ? JSON.parse(item.documentConfiguration)
      : undefined,
    ...(id ? { templateId: id } : {}),
    guidances: item.guidances
      ? item.guidances.map((i) => ({
          guidanceId: i.guidanceId,
          stepName: i.stepName,
          stepSection: i.stepSection,
        }))
      : undefined,
    tags: item.tags?.map((tag) =>
      tag.isNew ? { ...tag, id: undefined } : tag
    ),
  })),
});
