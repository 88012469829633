import Cookie from "universal-cookie";
import {
  readSdkToken,
  refreshToken,
  saveToken,
} from "../components/Authentication/helpers";
import apiConfig from "./apiConfig";
import { CSRFHeader, CSRFRequestTokenCookie } from "./constants";
import {
  defaultScopes,
  msalConfig,
} from "../components/Authentication/AuthConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export interface ApiRequest extends RequestInit {
  data?: any;
  baseEndpoint?: string;
  stringify?: boolean;
}

const Cookies = new Cookie();

export const apiHelper = async (
  url: string,
  config: ApiRequest = {},
  queryParams?: object,
  resetCSRF?: boolean
): Promise<Response> => {
  const getApiResponse = async (
    url: string,
    config: ApiRequest = {},
    queryParams?: object
  ): Promise<Response> => {
    config.method ??= "GET";
    config.headers ??= {};
    config.headers["Accept"] = "application/json, text/plain, */*";

    const csrfToken =
      Cookies.get(CSRFRequestTokenCookie) || Cookies.get("XSRF-TOKEN");
    if (csrfToken) {
      config.headers[CSRFHeader] = csrfToken;
    }
    config.credentials = config.credentials ?? "include";
    config.baseEndpoint = config.baseEndpoint ?? apiConfig.API_ROOT_URL;
    config.stringify = config.stringify ?? true;

    // JSON data stringify
    if (config.data && !["GET", "HEAD"].includes(config.method)) {
      if (!(config.body instanceof FormData)) {
        config.body = config.stringify
          ? JSON.stringify(config.data)
          : config.data;
        config.headers["Content-Type"] = "application/json";
      }
    }

    const queryParamsStr = queryParams ? getQueryParamsStr(queryParams) : "";

    const res = await sendRequestWithAuthorization(url, config, queryParamsStr);

    //NOTE: fetch doesn't throw exception for status between 400 and 600
    //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
    //so to simplify error handling we will throw custom error for any bad responses
    if (res.status === 401 || res.status === 302) {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();

      try {
        await refreshToken(msalInstance);
      } catch (error) {
        throw new ApiError("Unauthorized access!", res);
      }

      const newRes = await sendRequestWithAuthorization(
        url,
        config,
        queryParamsStr
      );

      if (newRes.status === 401 || newRes.status === 302) {
        throw new ApiError("Unauthorized access!", res);
      }

      if (!newRes.ok) {
        throw new ApiError("API unknown error!", res);
      }

      return newRes;
    }

    if (!res.ok) {
      throw new ApiError("API unknown error!", res);
    }

    return res;
  };

  //send request to get XSRF token if we don't have it already
  if (!Cookies.get(CSRFRequestTokenCookie) || resetCSRF) {
    await getApiResponse("/api/identity/GetAntiforgeryToken")
      .then((res) => res.json())
      .then((data) => {
        Cookies.set(CSRFRequestTokenCookie, data.antiForgeryCookieToken);
      });
  }
  return getApiResponse(url, config, queryParams);
};

const sendRequestWithAuthorization = async (
  url: string,
  config: ApiRequest,
  queryParamsStr: string
) => {
  const accessToken = apiConfig.USE_SDK_TOKEN
    ? readSdkToken()
    : sessionStorage.getItem(apiConfig.ACCESS_TOKEN_KEY) || "";
  if (accessToken && config.headers && !config.headers["Authorization"]) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  config.baseEndpoint = config.baseEndpoint ?? apiConfig.API_ROOT_URL;
  return await fetch(config.baseEndpoint.trim() + url + queryParamsStr, config);
};

const handleErrors = async (res: Response, retryOnAuthFail = false) => {};

class ApiError extends Error {
  response: Response;
  constructor(message: string, res: Response) {
    super(message);
    this.response = res;
  }
}

export const Api = {
  get: (url: string, queryParams?: object, config?: object) =>
    apiHelper(url, { method: "GET", ...config }, queryParams).then((res) =>
      res.status === 204 ? "" : res.json()
    ),
  post: (url: string, data: any) => apiHelper(url, { method: "POST", data }),
  put: (url: string, data?: any) => apiHelper(url, { method: "PUT", data }),
  delete: (url: string, data?: any) =>
    apiHelper(url, { method: "DELETE", data }),
  patch: (url: string, data?: any) => apiHelper(url, { method: "PATCH", data }),

  getFile: (url: string, data?: any, queryParams?: object) =>
    apiHelper(url, { method: "GET", data }, queryParams),
  postFile: (url: string, data: FormData) =>
    apiHelper(url, { method: "POST", body: data, stringify: false }),
  putFile: (url: string, data: FormData) =>
    apiHelper(url, { method: "PUT", body: data, stringify: false }),
};

export const getQueryParamsStr = (queryParams: object) => {
  let queryParamsStr = "";

  const keys = Object.keys(queryParams);
  const lastIndex = keys.length - 1;

  queryParamsStr = "?";

  keys.forEach((key: string, i: number) => {
    queryParamsStr += `${key}=${queryParams[key]}${i !== lastIndex ? "&" : ""}`;
  });

  return queryParamsStr;
};
