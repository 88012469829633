import { Link } from "react-router-dom";
import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  IServerSideDatasource,
  SortChangedEvent,
} from "ag-grid-community";
import clsx from "clsx";
import { Search } from "@appkit4/react-components/search";
import { Button } from "@appkit4/react-components/button";
import { Loading } from "@appkit4/react-components/loading";
import { NoResults } from "../../components/NoResults";
import ErrorPage from "../../components/ErrorPage";
import { messages } from "../../constants/messages";
import NoDocumentsIcon from "../../assets/NoDocuments.svg";
import Grid from "../../components/Grid";
import { Tile, TileName } from "./types";
import TileCard from "./components/TileCard";
import styles from "./TrackPresenter.module.scss";
import { HOST_APP_BASE_URL } from "../../constants/hostAppBaseUrl";
import MFE_ROUTES from "../../constants/mfeRoutes";

type Props = {
  columns: ColDef[];
  tilesView: Tile[];
  searchText: string;
  setSearchText: (value: string) => void;
  selectedTile: string | null;
  setSelectedTile: (value: TileName | null) => void;
  loading: boolean;
  isError: boolean;
  isTemplateDownloadAllowed: boolean;
  legacyLink?: string;
  onGridReady: (params: GridReadyEvent) => void;
  onGridDataRendered: (params: FirstDataRenderedEvent) => void;
  onFilterChanged: (filterParams: FilterChangedEvent) => void;
  onGridSortChanged: (sortParams: SortChangedEvent) => void;
  serverSideDataSource: IServerSideDatasource;
  noDocuments: boolean;
};

export default function TrackPresenter({
  columns,
  tilesView,
  searchText,
  setSearchText,
  selectedTile,
  setSelectedTile,
  loading,
  isError,
  legacyLink,
  isTemplateDownloadAllowed,
  onGridReady,
  onGridDataRendered,
  onFilterChanged,
  onGridSortChanged,
  serverSideDataSource,
  noDocuments,
}: Props) {
  const isFilterApplied = false;

  const hasNoData = !loading && noDocuments;
  const isFilterOrSearchApplied = searchText || isFilterApplied;

  return (
    <div className={styles.truck}>
      <div className={clsx("ap-flex", styles.header)}>
        <div className="ap-flex">
          <h1 className={clsx("ap-typography-heading ap-mr-spacing-4")}>
            Track Documents
          </h1>
          {legacyLink && (
            <a href={legacyLink}>
              <Button kind="secondary">Old Look Create For Tax</Button>
            </a>
          )}
        </div>

        <div className="ap-flex">
          {isTemplateDownloadAllowed && (
            <Link
              to={`${HOST_APP_BASE_URL}${MFE_ROUTES.DOWNLOAD_TEMPLATES}`}
              className="ap-mr-spacing-4"
            >
              <Button kind="secondary" icon="icon-download-light-outline">
                Download Template
              </Button>
            </Link>
          )}

          <Link to={`${HOST_APP_BASE_URL}${MFE_ROUTES.ENGAGEMENT}`}>
            <Button className="ap-full-width" icon="icon-plus-outline">
              Create Document
            </Button>
          </Link>
        </div>
      </div>

      <div className={styles.tiles}>
        {tilesView.map((tile) => (
          <TileCard
            key={tile.id}
            {...tile}
            isSelected={tile.id === selectedTile}
            onSelect={(v) => setSelectedTile(v ? (tile.id as TileName) : null)}
          />
        ))}
      </div>

      <>
        <div
          className={clsx(
            "ap-flex",
            "ap-justify-content-end",
            "ap-align-items-center",
            "ap-mb-spacing-6",
            "gap-4"
          )}
        >
          <Search
            searchType="secondary"
            placeholder={messages.common.searchPlaceholder}
            searchValue={searchText}
            onChange={(value) => setSearchText(value)}
            onClear={() => setSearchText("")}
            className={styles.search}
          />
        </div>
        <div
          className={clsx(
            styles.tableWrapper,
            styles.tableWrapperNotExpanded,
            loading && styles.disabled
          )}
        >
          <Grid
            columns={columns}
            withSidebar={true}
            treeData={false}
            onGridReady={onGridReady}
            onFirstDataRendered={onGridDataRendered}
            onFilterChanged={onFilterChanged}
            onSortChanged={onGridSortChanged}
            serverSideDataSource={serverSideDataSource}
            rowModelType="serverSide"
          />
          {isError ? (
            <ErrorPage message="Something Went Wrong, Please Refresh Your Browser." />
          ) : (
            <>
              {hasNoData && !searchText && isFilterApplied && (
                <div className={styles.empty}>
                  <NoResults
                    icon={NoDocumentsIcon}
                    mainMessage="No Documents Found"
                    additionalMessage="We couldn't find any results that met your filter criteria."
                  />
                </div>
              )}
              {hasNoData && !!searchText && (
                <div className={styles.empty}>
                  <NoResults
                    icon={NoDocumentsIcon}
                    mainMessage="No Documents Found"
                    additionalMessage="No results were found that match your search keywords. Try modifying your search criteria."
                  />
                  <Button onClick={() => setSearchText("")}>
                    Clear search
                  </Button>
                </div>
              )}
            </>
          )}
          {loading && (
            <Loading
              loadingType="circular"
              indeterminate
              className={styles.loading}
            />
          )}
        </div>
      </>
    </div>
  );
}
