import { v4 as uuid } from "uuid";

export const createNewSubTemplate = (isActive: boolean) => {
  return {
    id: uuid(),
    name: "",
    hasPwcContact: false,
    hasPwcSigner: false,
    uploadDocumentsAllowed: false,
    allowRedlining: false,
    documentConfiguration: "",
    docBotTemplateId: undefined,
    editorNotesId: undefined,
    isNew: true,
    isActive,
  };
};
